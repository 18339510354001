import revive_payload_client_6FKTn19ahC from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_6QozRdXg4e from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0XPZ9xWWj0 from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GWD9N5su6c from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_XxJDtTmbqx from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qY4QMWfPen from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_VhBiAYChhr from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/checkup/checkup/admin/.nuxt/components.plugin.mjs";
import prefetch_client_t1YHPSlAoK from "/home/runner/work/checkup/checkup/admin/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.16.11_encoding@0.1.13_eslint@8.57.0_ioredis@_pixhdftxzffixh7l3gkzxwflii/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import supabase_client_Vu6b6nytQn from "/home/runner/work/checkup/checkup/admin/src/plugins/supabase.client.ts";
import sentry_client_KAXFuL2wum from "/home/runner/work/checkup/checkup/admin/src/plugins/sentry.client.ts";
import dayjs_client_A2bzKURDlV from "/home/runner/work/checkup/checkup/admin/src/plugins/dayjs.client.ts";
import gtag_6Z9lu4VVCL from "/home/runner/work/checkup/checkup/admin/src/plugins/gtag.ts";
import i18n_YCWKwfVXF6 from "/home/runner/work/checkup/checkup/admin/src/plugins/i18n.ts";
import qrcode_client_gU8ufMWnQ5 from "/home/runner/work/checkup/checkup/admin/src/plugins/qrcode.client.ts";
import vuedraggable_ajhfMTWOKD from "/home/runner/work/checkup/checkup/admin/src/plugins/vuedraggable.ts";
export default [
  revive_payload_client_6FKTn19ahC,
  unhead_6QozRdXg4e,
  router_0XPZ9xWWj0,
  payload_client_GWD9N5su6c,
  navigation_repaint_client_XxJDtTmbqx,
  check_outdated_build_client_qY4QMWfPen,
  chunk_reload_client_VhBiAYChhr,
  components_plugin_KR1HBZs4kY,
  prefetch_client_t1YHPSlAoK,
  supabase_client_Vu6b6nytQn,
  sentry_client_KAXFuL2wum,
  dayjs_client_A2bzKURDlV,
  gtag_6Z9lu4VVCL,
  i18n_YCWKwfVXF6,
  qrcode_client_gU8ufMWnQ5,
  vuedraggable_ajhfMTWOKD
]