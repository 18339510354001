import type { User as SupabaseUser } from '@supabase/supabase-js'
import { getUserByAccountId } from '~/repositories/userRepository'
import type { User } from '~/entities/user'

const convertToCurrentUser = (user: User) => {
  return {
    ...user,
    canEditOrganization: user.role === 'ORGANIZATION_ADMIN',
    canEditLocation: user.role === 'ORGANIZATION_ADMIN' || user.role === 'LOCATION_ADMIN',
  }
}

const recoverUser =
  (supabaseUser: Ref<SupabaseUser | null>, userState: Ref<User | null>, isPendingState: Ref<boolean>) => async () => {
    if (supabaseUser.value) {
      const { user_accounts: userAccounts, ...user } = await getUserByAccountId(supabaseUser.value.id)
      if (userAccounts.length !== 1) throw new Error('invalid user account data')

      userState.value = user
      isPendingState.value = userAccounts[0].is_pending
    }
  }

export const useCurrentUser = () => {
  const supabaseUser = useSupabaseUser()
  const user = useState<User | null>('user')
  const isPending = useState<boolean>('isPending', () => false)

  const currentUser = computed(() => {
    return user.value ? convertToCurrentUser(user.value) : null
  })

  return {
    currentUser,
    isPending,
    recoverUser: recoverUser(supabaseUser, user, isPending),
    updateCurrentUser: (updatedUser: User | null) => (user.value = updatedUser),
  }
}
